export const LinksData = [
  {
    key: "ActivityPub(misskey)",
    detail: [
      {
        a: "bouvardia.icu(第二病棟) : @lapua@ward2.bouvardia.icu",
        link: "https://ward2.bouvardia.icu/@lapua",
      },
      {
        a: "cosmisskey : @lapuacase@cosmisskey.cc",
        link: "https://cosmisskey.cc/@lapuacase",
      },
    ],
  },
  {
    key: "X(twitter)",
    detail: [
      { a: "X(twitter): @lapuacase", link: "https://twitter.com/lapuacase" },
    ],
  },
  {
    key: "e-Mail",
    detail: [
      {
        a: "Address: brain(ぁ)mail.lapuacase.info",
        link: "",
      },
    ],
  },
];
