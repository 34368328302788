import React from "react";
import {
  AboutArea,
  KomokuColumn,
  // ProfileColumn,
  ProfileTable,
  ProfileTableRow,
  ProfileTableRowKey,
  ProfileTableRowValue,
} from "./AboutStyled";
import { ProfileData } from "./AboutData";

// 基本情報
function About() {
  return (
    <>
      <AboutArea>
        <KomokuColumn>
          <span style={{ fontSize: "50px" }}>Profile</span>
          <ProfileTable>
            {ProfileData.map((row) => (
              <ProfileTableRow>
                <ProfileTableRowKey>{row.key}</ProfileTableRowKey>
                <ProfileTableRowValue>{row.value}</ProfileTableRowValue>
              </ProfileTableRow>
            ))}
          </ProfileTable>
        </KomokuColumn>
      </AboutArea>
    </>
  );
}

export default About;
