import React, { useState } from "react";
import About from "./about/About";
import Links from "./links/Links";
import { MainBody, NameHeader } from "./styled/Styled";
import {
  ButtonArea,
  CategoryTab,
  CategoryTabSelected,
} from "./styled/ButtonAreaStyle";

function Index() {
  const [checked, setChecked] = useState(1);
  // const isMobileDevice = window.innerWidth < 820 ? true : false;

  return (
    <>
      <NameHeader>The Lapuacase</NameHeader>
      <br />
      <br />
      <>
        <ButtonArea>
          <br />
          {/* TODO styled-componentsの引数機能を用いてすっきりとした記述にする */}
          {checked === 1 && (
            <CategoryTabSelected onClick={() => setChecked(1)}>
              About
            </CategoryTabSelected>
          )}
          {checked !== 1 && (
            <CategoryTab onClick={() => setChecked(1)}>About</CategoryTab>
          )}
          {checked === 2 && (
            <CategoryTabSelected onClick={() => setChecked(2)}>
              Links
            </CategoryTabSelected>
          )}
          {checked !== 2 && (
            <CategoryTab onClick={() => setChecked(2)}>Links</CategoryTab>
          )}
        </ButtonArea>
        <MainBody>
          <br />
          {checked === 1 && <About />}
          {checked === 2 && <Links />}
        </MainBody>
      </>
    </>
  );
}

export default Index;
